@import url("https://fonts.googleapis.com/css2?family=Syne+Mono&display=swap");

html {
  background-color: #333;
  color: #fd393a;
  font-family: "Syne Mono", monospace;
}

a {
  color: #fd393a;
}

.flex-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 0 auto;
  overflow: auto;
}

.flex-item {
  align-self: auto;
}

#oily-logo {
  margin-top: 1em;
}

/* For devices larger than 400px */
@media (min-width: 600px) {
  .flex-container {
    max-width: 560px;
    /* height: 100%; */
    height: 100vh;
  }
  #oily-logo {
    width: 300px;
  }
}

/* For devices smaller than 400px */
@media (max-width: 599px) {
  .flex-container {
    max-width: 95%;
  }
  #oily-logo {
    width: 90%;
  }
}
